angular.module("vgresiduos").controller("ResidueEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"$q",
	"$state",
	"residueService",
	"accountService",
	"httpService",
	"fileService",
	"permissionService",
	"calculusService",
	"conama313Service",
	"engagementScoreService",
	function (
		$rootScope,
		$scope,
		$stateParams,
		$q,
		$state,
		residueService,
		accountService,
		httpService,
		fileService,
		permissionService,
		calculusService,
		conama313Service,
		engagementScoreService
	) {
		"use strict";

		const createEditDeletePermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.CreateEditDeleteResidueRecipient
		);
		$scope.createEditDeletePermission = createEditDeletePermission;

		$scope.residueId = $stateParams.id;
		$scope.isNewResidue = $stateParams.id ? false : true;
		$scope.myfiles = {
			files: []
		};

		const pictogramDeferred = $q.defer();
		const imageDeferred = $q.defer();
		const characterizationReportDeferred = $q.defer();

		const currentClient = accountService.getCurrentClient();

		$scope.saveOptions = {
			currentContext: "residue",
			back: function () {
				$state.go("residueList");
			},
			residue: {
				hasPermission: function () {
					return createEditDeletePermission;
				},
				valid: false,
				returnState: "residueList",
				onSaveError: function () {},
				save: function () {
					const deferred = $q.defer();
					if (!$scope.residueId) {
						engagementScoreService.track(Vgr.trackings.residue.create);
						const createDto = buildCreateResidueDto();
						httpService.postDTOToServiceV2(residueService.createClientResidue, createDto).then(
							function (response) {
								$scope.residue = buildInputResidue(response.Residue);
								$state.go("residueEdit", { id: $scope.residue.ID });
								deferred.resolve();
							},
							function () {
								deferred.reject();
							}
						);
					} else {
						const updateDto = buildUpdateResidueDto();
						httpService.postDTOToServiceV2(residueService.updateClientResidue, updateDto).then(
							function () {
								calculusService.clearCalculateConversionCache();
								deferred.resolve();
							},
							function () {
								deferred.reject();
							}
						);
					}
					return deferred.promise;
				},
				pictogramFileOptions: {
					fileService: "mixed",
					get: function () {
						return pictogramDeferred.promise;
					},
					update: function (newFiles, deletedIds) {
						$scope.uploadingPictogram = true;
						const deferrend = $q.defer();
						fileService.uploadAll(newFiles, GetResidueFilesPath()).then(
							function (response) {
								deferrend.resolve(response);
								$scope.uploadingPictogram = false;
							},
							function () {
								deferrend.reject();
								$scope.uploadingPictogram = false;
							}
						);
						return deferrend.promise;
					},
					fileList: []
				},
				imageFileOptions: {
					fileService: "mixed",
					get: function () {
						return imageDeferred.promise;
					},
					update: function (newFiles, deletedIds) {
						$scope.uploadingImage = true;
						const deferrend = $q.defer();
						fileService.uploadAll(newFiles, GetResidueFilesPath()).then(
							function (response) {
								deferrend.resolve(response);
								$scope.uploadingImage = false;
							},
							function () {
								deferrend.reject();
								$scope.uploadingImage = false;
							}
						);
						return deferrend.promise;
					},
					fileList: []
				},
				characterizationReportFileOptions: {
					fileService: "mixed",
					get: function () {
						return characterizationReportDeferred.promise;
					},
					update: function (newFiles, deletedIds) {
						$scope.uploadingCharacterizationReport = true;
						const deferrend = $q.defer();
						fileService.uploadAll(newFiles, GetResidueFilesPath()).then(
							function (response) {
								deferrend.resolve(response);
								$scope.uploadingCharacterizationReport = false;
							},
							function () {
								deferrend.reject();
								$scope.uploadingCharacterizationReport = false;
							}
						);
						return deferrend.promise;
					},
					fileList: []
				},
				validate: function () {
					if (
						$scope.residue.IbamaActivityDetail &&
						$scope.residue.IbamaActivityDetail.IbamaActivityCode &&
						!$scope.residue.IbamaActivityDetail.Code
					) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.IBAMA_ACTIVITY_DETAIL_MANDATORY);
						return false;
					}

					if (!$scope.residue.MRID || $scope.residue.MRID <= 0) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.RESIDUE_REQUIRED_ERROR);
						return false;
					}

					if ($scope.residue.MaximumQuantity === 0) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.MAXIMUM_QUANTITY_CANT_BE_ZERO);
						return false;
					}

					if (
						$scope.residue.MinimumQuantity != null &&
						$scope.residue.MaximumQuantity != null &&
						$scope.residue.MaximumQuantity < $scope.residue.MinimumQuantity
					) {
						$rootScope.$broadcast(
							Vgr.constants.evtShowErrorMessage,
							$rootScope.labels.RESIDUE_MINIMUM_QUANTITY_BIGGER_MAXIMUM_QUANTITY
						);
						return false;
					}

					if (!this.valid) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
					}

					return this.valid;
				}
			},
			emergencySheetEdit: {
				hasPermission: function () {
					return createEditDeletePermission;
				},
				valid: true,
				returnState: "residueList",
				save: function () {
					const deferred = $q.defer();

					const dto = createEmergencySheetDto();
					httpService.postDTOToServiceV2(residueService.updateEmergencySheet, dto).then(
						function (response) {
							$scope.inputEmergencySheet = response.EmergencySheet;
							deferred.resolve(response);
						},
						function () {
							deferred.reject();
						}
					);

					return deferred.promise;
				},
				validate: function () {
					if (!$scope.inputEmergencySheet.ProperName) {
						$rootScope.$broadcast(
							Vgr.constants.evtShowErrorMessage,
							$rootScope.labels.APPROPRIATE_NAME_REQUIRED_MESSAGE
						);
						return false;
					}

					return true;
				}
			},
			FDSR: {
				hasPermission: function () {
					return createEditDeletePermission;
				},
				valid: true,
				returnState: "residueList",
				save: function () {
					const deferred = $q.defer();

					const dto = createFDSRDto();

					httpService.postDTOToServiceV2(residueService.updateFDSR, dto).then(
						function (response) {
							$scope.inputFDSR = response.FDSR;
							deferred.resolve(response);
						},
						function () {
							deferred.reject();
						}
					);
					return deferred.promise;
				}
			},
			hommelDiamond: {
				hasPermission: function () {
					return createEditDeletePermission;
				},
				valid: true,
				returnState: "residueList",
				save: function () {
					const deferred = $q.defer();

					const dto = createHommelDiamondDto();

					httpService.postDTOToServiceV2(residueService.updateHommelDiamond, dto).then(
						function (response) {
							$scope.inputHommelDiamond = response.HommelDiamond;
							$scope.inputHommelDiamond.ShowHommelDiamond = true;
							deferred.resolve(response);
						},
						function () {
							deferred.reject();
						}
					);
					return deferred.promise;
				}
			}
		};

		function GetResidueFilesPath() {
			return "organization-units/" + currentClient.id + "/residues/" + $scope.residue.Guid + "/files";
		}

		function createEmergencySheetDto() {
			return {
				model: $scope.inputEmergencySheet,
				id: $scope.residue.ID
			};
		}

		function createHommelDiamondDto() {
			return {
				model: {
					Flammability: $scope.inputHommelDiamond.Flammability,
					Toxicity: $scope.inputHommelDiamond.Toxicity,
					OtherDangers: $scope.inputHommelDiamond.OtherDangers,
					Pathogenicity: $scope.inputHommelDiamond.Pathogenicity
				},
				id: $scope.residue.ID
			};
		}

		function createFDSRDto() {
			return {
				model: $scope.inputFDSR,
				id: $scope.residue.ID
			};
		}

		this.$onInit = function () {
			if ($scope.residueId && !isNaN($scope.residueId)) {
				httpService.getDTOFromServiceV2(residueService.getClientResidue, $scope.residueId).then(
					function (response) {
						$scope.residue = buildInputResidue(response.Residue);
						loadFiles(response.Residue);
						if ($scope.residue.Code) {
							$rootScope.contentNavBar.breadcrumb = $scope.residue.Code;
						}
					},
					function () {
						$state.go("residueList");
					}
				);
			} else {
				$scope.residue = {
					Guid: generateResidueGuid()
				};

				pictogramDeferred.resolve([]);
				imageDeferred.resolve([]);
				characterizationReportDeferred.resolve([]);
			}
		};

		function loadFiles(residue) {
			const pictogramFiles = [];
			const imageFiles = [];
			const characterizationReportFiles = [];

			for (const file of residue.Files) {
				if (file.Type == Vgr.enumerations.residue.File.Pictogram) {
					pictogramFiles.push(file);
				} else if (file.Type == Vgr.enumerations.residue.File.Image) {
					imageFiles.push(file);
				} else if (file.Type == Vgr.enumerations.residue.File.CharacterizationReport) {
					characterizationReportFiles.push(file);
				}
			}

			pictogramDeferred.resolve(pictogramFiles);
			imageDeferred.resolve(imageFiles);
			characterizationReportDeferred.resolve(characterizationReportFiles);
		}

		function generateResidueGuid() {
			return Vgr.util.uuid();
		}

		$scope.changeContext = function (context) {
			$scope.saveOptions.currentContext = context;
			if (context === "emergencySheetEdit") {
				loadEmergencySheet();
			}
			if (context === "FDSR") {
				loadFDSR();
			}
			if (context === "hommelDiamond") {
				loadHommelDiamond();
			}
		};

		function loadEmergencySheet() {
			httpService.getDTOFromServiceV2(residueService.getEmergencySheet, $scope.residue.ID).then(
				function (response) {
					$scope.inputEmergencySheet = response.EmergencySheet;
				},
				function () {
					$scope.inputEmergencySheet = {};
				}
			);
		}

		function loadFDSR() {
			httpService.getDTOFromServiceV2(residueService.getFDSR, $scope.residue.ID).then(
				function (data) {
					$scope.inputFDSR = data.FDSR;
					if ($scope.residue.ONUDTO && $scope.residue.ONUDTO.ID) {
						$scope.inputFDSR.ONULabel =
							"(" +
							$scope.residue.ONUDTO.Number +
							" - " +
							$scope.residue.ONUDTO.Risk.trim() +
							") " +
							$scope.residue.ONUDTO.Description;
					}
				},
				function () {
					$scope.inputFDSR = {};
				}
			);
		}

		function loadHommelDiamond() {
			httpService.getDTOFromServiceV2(residueService.getHommelDiamond, $scope.residue.ID).then(
				function (response) {
					$scope.inputHommelDiamond = response.HommelDiamond;
					$scope.inputHommelDiamond.ShowHommelDiamond = true;
				},
				function () {
					$scope.inputHommelDiamond = {};
				}
			);
		}

		$rootScope.contentNavBar = $scope.saveOptions;
		$rootScope.contentNavBar.title = $rootScope.labels.RESIDUES;
		$rootScope.contentNavBarGrid = false;

		$scope.setIbamaLabel = function (ibamaResidue) {
			if (ibamaResidue) {
				ibamaResidue.Label = ibamaResidue.Code + " - " + ibamaResidue.Description;
			}
		};

		$scope.setOnuDangerousProductLabel = function (onuDangerousProduct) {
			if (onuDangerousProduct) {
				onuDangerousProduct.Label = onuDangerousProduct.Number + " - " + onuDangerousProduct.ProperName;
				if (!onuDangerousProduct.Class) {
					onuDangerousProduct.Class = $rootScope.labels.ONU_DANGEROUS_PRODUCT_WITHOUT_CLASS;
				}
			}
		};

		$scope.setConama358Label = function (conama358) {
			if (conama358) {
				const group = conama358.group || conama358.Group;
				let groupId = null;
				if (group) {
					groupId = group.id || group.ID || group.Id;
				}

				const code = conama358.code || conama358.Code || "";
				const description = conama358.description || conama358.Description || "";

				conama358.Label =
					code + " - " + description + " - " + $rootScope.labels.CONAMA358_GROUP + ": " + getChar(groupId);
				conama358.GroupLabel = getChar(groupId);
				conama358.GroupDescription = getGroupLabel(groupId);
			}
		};

		function setConama313Label(conama313) {
			if (conama313) {
				conama313.Label = conama313Service.getLabelField(
					conama313,
					Vgr.enumerations.conama313.type.DangerousAndNotDangerousResidues
				);
			}
		}

		$scope.setNbrLabel = function (nbr) {
			if (nbr) {
				if (nbr.number) {
					nbr.Label = nbr.number + " - " + nbr.description;
				} else {
					nbr.Label = nbr.Number + " - " + nbr.Description;
				}
			}
		};

		function getChar(num) {
			switch (num) {
				case 1:
					return "A";
				case 2:
					return "B";
				case 3:
					return "C";
				case 4:
					return "D";
				case 5:
					return "E";
			}
		}

		function getGroupLabel(num) {
			switch (num) {
				case 1:
					return $rootScope.labels.CONAMA358_GROUP_A;
				case 2:
					return $rootScope.labels.CONAMA358_GROUP_B;
				case 3:
					return $rootScope.labels.CONAMA358_GROUP_C;
				case 4:
					return $rootScope.labels.CONAMA358_GROUP_D;
				case 5:
					return $rootScope.labels.CONAMA358_GROUP_E;
			}
		}

		function buildInputResidue(residue) {
			const inputResidue = {
				ResidueId: residue.ResidueId,
				Guid: residue.Guid ? residue.Guid : generateResidueGuid(),
				Active: residue.Active,
				Capacity: residue.StorageCapacity,
				Conama313DTO: residue.Conama313
					? {
							...residue.Conama313,
							id: residue.Conama313.ID,
							code: residue.Conama313.Code1,
							code2: residue.Conama313.Code2,
							description: residue.Conama313.Description
					  }
					: undefined,
				Conama358DTO: residue.Conama358,
				DefaultDisposalId: residue.DefaultDisposalId,
				Description: residue.Name,
				GeneralResidueName: residue.GeneralResidueName,
				ID: residue.Id,
				Code: residue.Code,
				IbamaResidue: residue.IbamaResidue,
				MeasureUnit: residue.MeasureUnit,
				InternalDenomination: residue.Name,
				DescriptionPt: residue.NamePt,
				DescriptionEsp: residue.NameEsp,
				MRID: residue.GeneralResidueId,
				NBRDTO: residue.NBR,
				OnuDangerousProduct: residue.ONUDangerousProduct,
				ONUPackingGroupId: residue.ONUPackingGroup ? residue.ONUPackingGroup.Id : null,
				PhysicStateDTO: residue.PhysicalState,
				RecipientDTO: residue.Recipient
					? {
							ID: residue.Recipient.Id
					  }
					: null,
				Requirements: residue.Requirements,
				ResidueGroups: residue.ResidueGroups,
				StorageAlertPercentage: residue.StorageAlertPercentage,
				TagList: residue.Tags,
				UnitConversionFactor: residue.UnitConversionFactor,
				VolumeConversionFactor: residue.VolumeConversionFactor,
				ResidueClassType: residue.ResidueClass,
				MinimumQuantity: residue.MinimumQuantity,
				MaximumQuantity: residue.MaximumQuantity,
				QuantityLimitActive: residue.MinimumQuantity != null || residue.MaximumQuantity != null,
				UseRecipientQuantity: residue.UseRecipientQuantity,
				IbamaActivityDetail: residue.IbamaActivityDetail,
				IbamaActivityDetailCode: residue.IbamaActivityDetail ? residue.IbamaActivityDetail.Code : null,
				IbamaActivityCode: residue.IbamaActivityDetail ? residue.IbamaActivityDetail.IbamaActivityCode : null
			};

			$scope.setIbamaLabel(residue.IbamaResidue);
			setConama313Label(inputResidue.Conama313DTO);
			$scope.setOnuDangerousProductLabel(residue.ONUDangerousProduct);
			$scope.setConama358Label(residue.Conama358);
			$scope.setNbrLabel(residue.NBR);

			return inputResidue;
		}

		function buildCreateResidueDto() {
			const dto = getBaseResidueModel();
			dto.ResidueId = $scope.residue.MRID;
			return dto;
		}

		function buildUpdateResidueDto() {
			const dto = {};
			dto.model = getBaseResidueModel();
			dto.model.Active = $scope.residue.Active;
			dto.model.ResidueId = $scope.residue.MRID;
			dto.id = $scope.residue.ID;
			return dto;
		}

		function getBaseResidueModel() {
			const model = {
				InternalDenomination: $scope.residue.DescriptionPt,
				DescriptionEsp: $scope.residue.DescriptionEsp,
				Conama313Id: $scope.residue.Conama313DTO ? $scope.residue.Conama313DTO.id : null,
				Conama358Id: $scope.residue.Conama358DTO ? $scope.residue.Conama358DTO.id : null,
				ONUId: $scope.residue.OnuDangerousProduct ? $scope.residue.OnuDangerousProduct.Id : null,
				ONUPackingGroupId: $scope.residue.ONUPackingGroupId,
				NBRId: $scope.residue.NBRDTO ? $scope.residue.NBRDTO.code : null,
				IbamaResidueId: $scope.residue.IbamaResidue ? $scope.residue.IbamaResidue.Id : null,
				MeasureUnitId: $scope.residue.MeasureUnit ? $scope.residue.MeasureUnit.Id : null,
				ResidueClassId: $scope.residue.ResidueClassType.Id,
				StorageCapacity: $scope.residue.Capacity,
				StorageAlertPercentage: $scope.residue.StorageAlertPercentage,
				PhysicalStateId: $scope.residue.PhysicStateDTO ? $scope.residue.PhysicStateDTO.ID : null,
				Requirements: $scope.residue.Requirements,
				RecipientId: $scope.residue.RecipientDTO ? $scope.residue.RecipientDTO.ID : null,
				DisposalId: $scope.residue.DisposalType ? $scope.residue.DisposalType.Id : null,
				VolumeConversionFactor: $scope.residue.VolumeConversionFactor,
				UnitConversionFactor: $scope.residue.UnitConversionFactor,
				MinimumQuantity: $scope.residue.QuantityLimitActive ? $scope.residue.MinimumQuantity : null,
				MaximumQuantity: $scope.residue.QuantityLimitActive ? $scope.residue.MaximumQuantity : null,
				UseRecipientQuantity: $scope.residue.UseRecipientQuantity,
				IbamaActivityCode: $scope.residue.IbamaActivityDetail
					? $scope.residue.IbamaActivityDetail.IbamaActivityCode
					: null,
				IbamaActivityDetailCode: $scope.residue.IbamaActivityDetail ? $scope.residue.IbamaActivityDetail.Code : null,

				Guid: $scope.residue.Guid,

				Code: $scope.residue.Code,

				Tags: [],
				Files: []
			};

			if ($scope.residue.TagList && $scope.residue.TagList.length) {
				for (let i = 0; i < $scope.residue.TagList.length; i++) {
					model.Tags.push($scope.residue.TagList[i].Name);
				}
			}

			for (var file of $scope.saveOptions.residue.pictogramFileOptions.fileList) {
				file.Type = Vgr.enumerations.residue.File.Pictogram;
				model.Files.push(file);
			}
			for (var file of $scope.saveOptions.residue.imageFileOptions.fileList) {
				file.Type = Vgr.enumerations.residue.File.Image;
				model.Files.push(file);
			}
			for (var file of $scope.saveOptions.residue.characterizationReportFileOptions.fileList) {
				file.Type = Vgr.enumerations.residue.File.CharacterizationReport;
				model.Files.push(file);
			}

			return model;
		}
	}
]);
