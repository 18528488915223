angular.module("vgresiduos").factory("disposalCostService", [
	"$http",
	"$q",
	"accountService",
	function ($http, $q, accountService) {
		"use strict";

		const disposalCostService = {};

		function getDisposalDocumentsBaseRequestUrl() {
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.disposalCosts.replace(
					"[ORGANIZATION]",
					accountService.getCurrentClient().organization.id
				)
			);
		}

		function _updateDisposalCosts(dto) {
			const deferred = $q.defer();

			updateDisposalCostsInternal(dto).then(
				function (response) {
					deferred.resolve({ data: _formatDisposalCost(response.data) });
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function _calculateDisposalCosts(disposalcostRequest) {
			const deferred = $q.defer();

			calculateDisposalCostsInformationInternal(disposalcostRequest).then(
				function (response) {
					deferred.resolve(_formatDisposalCost(response.data));
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function _formatDisposalCost(disposalCost) {
			if (disposalCost) {
				disposalCost = Vgr.util.convertKeysToCamel(disposalCost);

				return {
					...disposalCost,
					pricingType:
						disposalCost.disposalCost != null
							? Vgr.enumerations.destination.disposalCostType.CostByDisposal
							: Vgr.enumerations.destination.disposalCostType.CostByResidue,
					disposalCostInCome: disposalCost.disposalCost > 0,
					residueCosts: disposalCost.residueCosts.map((r) => {
						return { ...r, residueCostInCome: r.unitaryPrice > 0 };
					})
				};
			}
		}

		function _fillDisposalCostResidueByGrid(disposalCost, residues) {
			disposalCost.absoluteShippingCost = Math.abs(disposalCost.shippingCost ?? 0);
			disposalCost.absoluteDisposalCost = disposalCost.disposalCost ? Math.abs(disposalCost.disposalCost) : null;
			disposalCost.absoluteOtherCost = Math.abs(disposalCost.otherCost ?? 0);
			disposalCost.residueCosts = residues.map((r) => {
				const residueCostSelected = disposalCost.residueCosts.find(
					(residue) =>
						(residue.residueId == r.ResidueGuidId || residue.id == r.ResidueGuidId || r.Code == residue.code) &&
						r.MeasureUnitId == residue.measureUnitId
				);
				return {
					...residueCostSelected,
					id: residueCostSelected?.id ?? r.ResidueGuidId,
					cost: isNaN(residueCostSelected?.unitaryPrice * residueCostSelected?.residueQuantity)
						? 0
						: residueCostSelected?.unitaryPrice * residueCostSelected?.residueQuantity,
					unitaryPrice: residueCostSelected?.unitaryPrice ?? 0,
					absoluteUnitaryPrice: Math.abs(residueCostSelected?.unitaryPrice ?? 0),
					residueName: r.ResidueName,
					quantityString: `${r.QuantityString} ${r.MeasureUnitAbbreviation}`,
					residueQuantity: r.Quantity ?? residueCostSelected?.residueQuantity,
					recipientId: r.RecipientGuidId,
					measureUnitId: residueCostSelected?.measureUnitId ?? r.MeasureUnitId
				};
			});
			return disposalCost;
		}

		function _getUpdateCostsDto(costModel, destinationModel) {
			const isDisposalCostType = costModel.pricingType == Vgr.enumerations.destination.disposalCostType.CostByDisposal;
			return {
				disposalCost: isDisposalCostType ? costModel.disposalCost : null,
				shippingCost: (costModel.absoluteShippingCost ?? 0) * -1,
				otherCosts: (costModel.absoluteOtherCost ?? 0) * -1,
				organizationUnitCode: destinationModel.Client.Code,
				disposalId: destinationModel.Id,
				residueCosts: isDisposalCostType ? [] : _internalGetResiduesDto(costModel),
				PricingType: costModel.pricingType
			};
		}

		function _internalGetResiduesDto(costModel) {
			return costModel.residueCosts.map((r) => {
				return {
					residueId: r.residueId ?? r.id,
					unitaryPrice: r.unitaryPrice,
					measureUnitId: r.measureUnitId,
					shippingCostContractId: r.shippingCostContractId
				};
			});
		}

		const calculateDisposalCostsInformationInternal = function (disposalcostRequest) {
			disposalcostRequest.OrganizationUnitCode = accountService.getCurrentClient().code;
			const req = {
				method: "post",
				data: disposalcostRequest,
				url: getDisposalDocumentsBaseRequestUrl()
			};

			return $http(req);
		};

		const updateDisposalCostsInternal = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV2.disposal.replace("[ORGANIZATION_UNIT]", dto.organizationUnitCode) +
					dto.disposalId +
					"/costs"
			};
			return $http(req);
		};

		disposalCostService.updateDisposalCosts = _updateDisposalCosts;
		disposalCostService.calculateDisposalCosts = _calculateDisposalCosts;

		//external conversions
		disposalCostService.formatDisposalCost = _formatDisposalCost;
		disposalCostService.fillDisposalCostResidueByGrid = _fillDisposalCostResidueByGrid;
		disposalCostService.getUpdateCostsDto = _getUpdateCostsDto;

		return disposalCostService;
	}
]);
