Vgr.resources = {
	api: {
		dashboard: "api/DashboardResource/",
		user: "api/UserResource/",
		client: "api/ClientResource/",
		report: "api/ReportResource/"
	},
	apiV1: {
		default: "api/v1/",
		audits: "api/v1/audits/",
		clients: "api/v1/clients/",
		residues: "api/v1/residues/",
		clientResidues: "api/v1/client-residues/",
		measureUnits: "api/v1/measure-units/",
		externalSystem: "api/v1/external-systems/",
		disposals: "api/v1/disposals/",
		users: "api/v1/users/",
		suppliers: "api/v1/suppliers/",
		calculus: "api/v1/calculus/",
		quantitiesConversions: "api/v1/calculus/residues/[RESIDUE_CODE]/quantities-conversions/",
		pendencies: "api/v1/pendencies/",
		organization: "api/v1/organizations/",
		ibamaResidues: "api/v1/ibama-residues/",
		scale: "api/v1/scales/"
	},
	apiV2: {
		reports: "v1/reports/",
		ibamaIntegrations: "v1/organizations/[ORGANIZATION]/ibama-rapp-integrations/",
		dnitIntegration: "v1/organizations/[ORGANIZATION_ID]/dnit-integrations/report",

		disposal: "v2/organization-units/[ORGANIZATION_UNIT]/disposals/",
		disposalsHistory: "v2/organization-units/[ORGANIZATION_UNIT]/disposals/history",
		disposalsForFollowup: "v2/organization-units/[ORGANIZATION_UNIT]/disposals/followup",
		disposalIntegration: "v2/organization-units/[ORGANIZATION_UNIT]/disposals/[DISPOSAL]/integrations/",
		temporaryStoragerReceiveDisposal:
			"v2/organization-units/[ORGANIZATION_UNIT]/disposals/[DISPOSAL]/temporary-storager-receivement/",
		masterDisposalIntegrations: "v1/master/disposal-integrations/"
	},
	core: {
		accounts: "v1/accounts/",
		accountInfo: "v1/info/",
		organizationAreas: "v1/organizations/[ORGANIZATION_ID]/areas/",
		areas: "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT_CODE]/areas/",
		systemPermissions: "v1/system-permissions/",
		users: "v1/users/",
		organizationUsers: "v1/organizations/[ORGANIZATION]/users/",
		organizationUnits: "v1/organizations/[ORGANIZATION]/organization-units/",
		organizationUnitPermissions:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT_CODE]/permission-groups/",
		organizationPermissions: "v1/organizations/[ORGANIZATION_ID]/permission-groups/",
		additionalProperties: "v1/organizations/[ORGANIZATION_ID]/additional-properties",
		organizationProducts: "v1/organizations/[ORGANIZATION_ID]/products/",
		externalSystems: "v1/organization-units/[ORGANIZATION_UNIT]/external-systems/",
		masterUsers: "v1/master/users",
		masterOrganizationUnits: "v1/master/organization-units",
		masterOrganizations: "v1/master/organizations",
		actionPlans: "v1/organizations/[ORGANIZATION_ID]/suppliers/[SUPPLIER_ID]/action-plans/",
		supplierActionPlans: "v1/supplier-organizations/[SUPPLIER_ORGANIZATION_ID]/units/[UNIT_ID]/action-plans/",
		ibamaActivities: "v1/ibama-activities/",
		organization: "v1/organizations/",
		ibamaSystemClient: "v1/organizations/[ORGANIZATION]/ibama-clients/",
		residueOrganizationUnit: "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/residues",
		residueOrganization: "v1/organizations/[ORGANIZATION_ID]/residues",
		organizationRecipients: "v1/organizations/[ORGANIZATION_ID]/recipients",
		residueClass: "v1/residue-class",
		residueGroups: "v1/residue-groups",
		organizationUnitRecipient:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATIONUNIT_CODE]/recipients",
		systemRecipients: "v1/system-recipients",
		cities: "v1/cities",
		states: "v1/states",
		countries: "v1/countries",
		disposalTypes: "v1/disposal-types",
		directives: "v1/organizations/[ORGANIZATION_ID]/directives"
	},
	bi: {
		goals: "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/goals",
		goalReports: "v1/organizations/[ORGANIZATION_ID]/reports/goals",
		reports: "v1/organizations/[ORGANIZATION_ID]/reports/",
		kpiReports: "v1/organizations/[ORGANIZATION_ID]/kpi-reports/",
		systemReports: "v1/system-reports"
	},
	sharedServices: {
		featureToggles: "v1/feature-toggles/",
		trackings: "v2/trackings/",
		files: "v1/organizations/[ORGANIZATION_ID]/files"
	},
	residuesManagement: {
		storagePreferences: "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/storage-preferences",
		listDisposalManifests: "v1/organizations/[ORGANIZATION_ID]/disposal-manifests",
		updateDisposalManifests:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/disposals/[DISPOSAL_ID]/system-sources/[SOURCE_ID]",
		stockControlPreferences:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/stock-control-preferences",
		generateDisposalDocuments:
			"v1/organizations/[ORGANIZATION]/organization-units/[ORGANIZATION_UNIT]/disposals/[DISPOSAL_ID]/documents",
		disposalsForFollowup:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/disposals/followup",
		disposalsHistory: "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/disposals/history",
		exportDisposalsHistory:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/disposals/exportation",
		disposalPreferences:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/disposal-preferences",
		disposalIntegration: "v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/integration-systems"
	},
	residuesGeneration: {
		model:
			"v1/organizations/[ORGANIZATION_ID]/organization-units/[ORGANIZATION_UNIT]/residue-generation-models/[RESIDUE_GENERATION_MODEL_ID]"
	},
	residues: {
		residues: "v1/organizations/[ORGANIZATION]/residues/",
		organizationUnitResidues: "v1/organizations/[ORGANIZATION]/organization-units/[ORGANIZATION_UNIT]/residues/",
		residueClass: "v1/residue-classes/",
		onuPictograms: "v1/onu-pictograms/",
		nbrs: "v1/nbrs/",
		conama358: "v1/conama-358/"
	},
	suppliers: {
		suppliers: "v1/organizations/[ORGANIZATION]/suppliers/",
		preferences: "v1/organizations/[ORGANIZATION]/supplier-preferences",
		organizationDocument: "v1/organizations/[ORGANIZATION]/suppliers/[SUPPLIER]/documents",
		supplierViewAudits: "v1/supplier-view/organizations/[ORGANIZATION]/organization-units/[UNIT]/audits",
		organizationUnitDocument:
			"v1/organizations/[ORGANIZATION]/organization-units/[ORGANIZATION_UNIT]/suppliers/[SUPPLIER]/documents",
		documentTypes: "v1/organizations/[ORGANIZATION]/document-types",
		supplierVehicles: "v1/organizations/[ORGANIZATION]/suppliers/[SUPPLIER_CODE]/vehicles",
		supplierDrivers: "v1/organizations/[ORGANIZATION]/suppliers/[SUPPLIER_CODE]/drivers",
		supplierContacts: "v1/organizations/[ORGANIZATION]/suppliers/[SUPPLIER_CODE]/contacts",
		disposalDocuments: "v1/organizations/[ORGANIZATION]/disposals/documents",
		disposalCosts: "v1/organizations/[ORGANIZATION]/disposals/costs",
		supplierProfiles: "v1/organizations/[ORGANIZATION]/suppliers/profiles",
		documentsReport: "v1/organizations/[ORGANIZATION]/documents/report"
	},
	integrations: {
		provisionalMtr:
			"v1/organizations/[ORGANIZATION]/organization-units/[ORGANIZATION_UNIT]/external-systems/[EXTERNAL_SYSTEM]/provisional-mtrs/"
	},
	local: {
		apiV1: {
			scales: "api/v1/scales/"
		}
	}
};
