angular.module("vgresiduos").controller("ReplicateForClientsTwoStepsPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"replicateType",
	"supplierId",
	"requestModel",
	"$sce",
	function ($scope, $rootScope, dialogService, replicateType, supplierId, requestModel, $sce) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.popupTitle = $scope.labels.REPLICATE_SUPPLIERS_TO_CLIENTS;
		$scope.replicateType = replicateType;
		$scope.supplierId = supplierId;
		$scope.requestModel = requestModel;

		var selectedSuppliersCount = requestModel.codes.length;
		$scope.isLoadingReplication = false;

		$scope.steps = [
			{
				title: $scope.labels.UNITS
			},
			{
				title: $scope.labels.CONFIRMATION,
				isDisabled: true
			}
		];

		$scope.currentStepIndex = 0;

		var selectedClientsCount;

		$scope.onChangeStep = function (stepIndex) {
			setCurrentStep(stepIndex);
			if (stepIndex == 0) {
				clear();
			}
			setPopupActionButtons();
		};

		$scope.getSelectedClientsCount = function (count) {
			selectedClientsCount = count;
		};

		function clear() {
			$scope.steps[1].isDisabled = true;
			$scope.popupActionButtons.push(continueButton);
			$scope.popupActionButtons.forEach((button) => (button.disabled = false));
		}

		function getFormattedMessage() {
			let message = $rootScope.labels.MSG_SUPPLIERS_TO_CLIENTS;
			if (message) {
				message = message.replace("[NUMERO]", '<b class="f-s-15">' + selectedSuppliersCount + "</b>");
				message = message.replace("[DIRETIVA]", '<b class="f-s-15">' + selectedClientsCount + "</b>");
				return $sce.trustAsHtml(message);
			}
			return "";
		}

		function setPopupActionButtons() {
			if ($scope.currentStepIndex > 0) {
				$scope.popupActionButtons = [goBackButton, replicateButton];
			} else {
				$scope.popupActionButtons = [closeButton];
			}
		}

		const goBackButton = {
			label: $scope.labels.GO_BACK,
			class: "md-primary",
			click: function () {
				setStep(0);
				clear();
			},
			disable: false
		};

		const closeButton = {
			label: $scope.labels.CLOSE,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disable: false
		};

		const continueButton = {
			label: $scope.labels.CONTINUE,
			class: "md-primary md-raised",
			click: function () {
				$rootScope.$broadcast(Vgr.constants.evtGetSelectedCount);
				if (selectedClientsCount == 0) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.NO_CLIENT_SELECTED);
					continueButton.disable = true;
				}
				if (selectedClientsCount != 0) {
					setStep(1);
					$scope.customMessage = getFormattedMessage();
				}
			},
			disable: false
		};

		const replicateButton = {
			label: $scope.labels.REPLICATE,
			class: "md-primary md-raised",
			click: function () {
				$scope.isLoadingReplication = true;
				this.disabled = true;
				$rootScope.$broadcast(Vgr.constants.evtConfimReplicateForClients);
			},
			disable: false
		};

		function setCurrentStep(index) {
			$scope.currentStepIndex = index;
			$scope.goToStepIndex = index;
		}

		function setStep(index) {
			$scope.steps[index].isDisabled = false;
			$scope.currentStepIndex = index;
			$scope.goToStepIndex = index;
			setPopupActionButtons();
		}

		$scope.onLoadClientsFinish = function (hasClientsToReplicate) {
			if (hasClientsToReplicate) {
				$scope.popupActionButtons.push(continueButton);
			}
		};

		$scope.onReplicateFinish = function () {
			replicateButton.disabled = false;
			dialogService.confirm();
		};

		function initializePopup() {
			setPopupActionButtons();
		}

		initializePopup();
	}
]);
