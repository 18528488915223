angular.module("vgresiduos").component("measureUnitQuantity", {
	controller: "MeasureUnitQuantityCtrl",
	templateUrl: "views/components/fields/measureUnit/measureUnitQuantity_template.html",
	bindings: {
		onlyResidueUnits: "<?",
		isDisabled: "<?",
		quantityLabel: "@?",

		setterMeasureUnitId: "<?",
		setterVolumeConversionFactor: "<?",
		setterUnitConversionFactor: "<?",

		quantity: "=",
		measureUnit: "=",
		conversionFactor: "=",
		convertedQuantity: "=?",

		onMeasureUnitChange: "<?",
		onCalculatorButtonClick: "<?"
	}
});
