angular.module("vgresiduos").factory("customReportService", [
	"$http",
	"$rootScope",
	"accountService",
	"engagementScoreService",
	function ($http, $rootScope, accountService, engagementScoreService) {
		"use strict";

		const _listReportTypes = function () {
			return [
				{
					description: $rootScope.labels.KEY_PERFORMANCE_INDICATORS,
					id: "Kpi"
				}
			];
		};

		const _getReportType = function (id) {
			return _listReportTypes().find(function (reportType) {
				return reportType.id == id;
			});
		};

		const _listReports = function ListReports() {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "get",
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.reports.replace("[ORGANIZATION_ID]", organizationId)
			};

			return $http(req);
		};

		const _deleteReport = function (reportId) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "delete",
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.reports.replace("[ORGANIZATION_ID]", organizationId) + reportId
			};

			return $http(req);
		};

		const _createCustomReport = function (dto) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "post",
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.reports.replace("[ORGANIZATION_ID]", organizationId),
				data: dto
			};

			return $http(req);
		};

		const _updateCustomReportFilters = function (dto) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "put",
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.reports.replace("[ORGANIZATION_ID]", organizationId) +
					dto.id +
					"/kpi",
				data: dto.model
			};

			return $http(req);
		};

		const _getCustomReport = function (reportId) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "get",
				url: `${Vgr.constants.biHostUrl}${Vgr.resources.bi.reports.replace(
					"[ORGANIZATION_ID]",
					organizationId
				)}${reportId}?updateUserAccess=true`
			};

			return $http(req);
		};

		const _updateFavorite = function (reportId, isFavorite) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const body = {
				isFavorite: isFavorite
			};

			const req = {
				method: "PUT",
				url: `${Vgr.constants.biHostUrl}${Vgr.resources.bi.reports.replace(
					"[ORGANIZATION_ID]",
					organizationId
				)}${reportId}/user`,
				data: body
			};

			return $http(req);
		};

		const _generateReport = function (reportId) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "post",
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.reports.replace("[ORGANIZATION_ID]", organizationId) +
					reportId +
					"/data"
			};

			return $http(req);
		};

		const _editReportName = function (dto) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "PUT",
				data: dto,
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.reports.replace("[ORGANIZATION_ID]", organizationId) + dto.id
			};

			return $http(req);
		};

		const _generateGenericKpiReport = function (dto) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "post",
				url: Vgr.constants.biHostUrl + Vgr.resources.bi.kpiReports.replace("[ORGANIZATION_ID]", organizationId),
				data: dto
			};

			return $http(req);
		};

		const _trackReportMetric = function (informationId) {
			const information = informationId.toUpperCase();

			if (information == Vgr.enumerations.customReport.information.ResidueGenerationQuantity) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.residueGenerationQuantity);
			} else if (information == Vgr.enumerations.customReport.information.ResidueDisposalQuantity) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.residueDisposalQuantity);
			} else if (information == Vgr.enumerations.customReport.information.ResidueDisposalPrice) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.residueDisposalCosts);
			} else if (information == Vgr.enumerations.customReport.information.ResidueDisposalRecipientEfficiency) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.residueDisposalRecipientEfficiency);
			} else if (information == Vgr.enumerations.customReport.information.ProductionQuantity) {
				engagementScoreService.track(Vgr.trackings.bi.metrics.productionQuantity);
			}
		};

		return {
			listReportTypes: _listReportTypes,
			getReportType: _getReportType,
			listReports: _listReports,
			deleteReport: _deleteReport,
			createCustomReport: _createCustomReport,
			updateCustomReportFilters: _updateCustomReportFilters,
			getCustomReport: _getCustomReport,
			updateFavorite: _updateFavorite,
			generateReport: _generateReport,
			editReportName: _editReportName,
			generateGenericKpiReport: _generateGenericKpiReport,

			trackReportMetric: _trackReportMetric
		};
	}
]);
