angular.module("vgresiduos").directive("replicateForClients", function () {
	return {
		scope: true,
		controller: "ReplicateForClientsCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/suppliers/replicateForClients/replicateForClients_template.html",
		bindToController: {
			replicateType: "<",
			supplierId: "<",
			requestModel: "<",
			getSelectedClientsCount: "<?",
			onReplicateFinish: "<?",
			onLoadClientsFinish: "<?"
		}
	};
});
